import { constants } from "../utils";


function MainBodyBanner() {
    const sdrfBannerDesktop = require('../images/sdf-banner-min.png')
    const sdrfBannerMobile = require('../images/sdf-banner-mobile-min.png')
    return (
        <div className="sdc-wide-banner">
            <img
                className="sdc-wide-banner-desktop"
                src={sdrfBannerDesktop}
                alt={constants.SDR_FLAIR_LOGO_ALT_TEXT}
                width="100%"
                height="">
            </img>
            <img
                className="sdc-wide-banner-mobile"
                src={sdrfBannerMobile}
                alt={constants.SDR_FLAIR_LOGO_ALT_TEXT}
                width="100%"
                height="">
            </img>
        </div>
    );
}

function MainBodyStatistics() {
    return (
        <div className="hp-statistics">
            <h2>our numbers</h2>

            <div className="clr"></div>

            <div className="hp-statistics-div border-right">
                <h3>15K+</h3>
                <h4>Merchants<br />Served So Far</h4>
            </div>

            <div className="hp-statistics-div border-left">
                <h3>13K+</h3>
                <h4>Amazon <br />Merchants Served</h4>
            </div>

            <div className="hp-statistics-div border-right">
                <h3>8K+</h3>
                <h4>Walmart <br />Merchants Served</h4>
            </div>

            <div className="hp-statistics-div border-left">
                <h3>70+</h3>
                <h4>Skilled <br />Team</h4>
            </div>

            <div className="clr"></div>

            <div className="hp-statistics-div border-right">
                <h3>2.3K+</h3>
                <h4>Facebook <br />Followers</h4>
            </div>

            <div className="hp-statistics-div border-left">
                <h3>340+</h3>
                <h4>Instagram<br /> Followers</h4>
            </div>

            <div className="hp-statistics-div border-right">
                <h3>180+</h3>
                <h4>Youtube<br />Subscribers</h4>
            </div>

            <div className="hp-statistics-div border-right">
                <h3>100+</h3>
                <h4>Google<br /> Reviews</h4>
            </div>
        </div>
    );
}

function MainBodyDataExploratorySection() {
    return (
        <div id="shopify-section-1620411854e404eaf1" className="shopify-section section-w-margin section-w-mobile-padding">
            <div className="ten-tenths lap--full-width push-left-one-tenth lap--push-left-none">
                <div className="hp-heading1">
                    <div className="container numbers-left">
                        <h3>{constants.SDR_FLAIR_STATISTICS.header}</h3>
                        <p>{constants.SDR_FLAIR_STATISTICS.description}</p>
                    </div>

                    <MainBodyStatistics />

                    <div className="clr"></div>
                </div>
            </div>
        </div>
    );
}

function getTechnologyMarqueeItem(marqueeItem, index) {
    return (
        <div className="marquee-item" key={index}>
            <img
                alt={marqueeItem.altText}
                src={marqueeItem.link}
            ></img>
        </div>
    );
}

function MainBodyTechnologySection() {
    const technologyMarqueeItems = constants.SDR_FLAIR_TECH_MARQUEE_ITEMS.map((item) => {
        return getTechnologyMarqueeItem(item)
    });

    return (
        <div className="sdf-tech-logos-outer">
            <div className="footer-partner-logos sdf-tech-logos">
                <div className="footer-partner-logos-inner">
                    <div className="marquee">
                        <div className="marquee-content">
                            {technologyMarqueeItems}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function MainBodyDashboardSection() {
    const sdrFlairDashboardBanner = require('../images/sdf-img-1-v2.png')
    return (
        <section className="sdf-section-2">
            <div className="sdf-section-2-inner">
                <div className="sdf-section-2-img">
                    <img
                        className="sdf-section-2-img"
                        src={sdrFlairDashboardBanner}
                        alt={constants.SDR_FLAIR_LOGO_ALT_TEXT}
                        width="100%"
                        height="">
                    </img>
                </div>
                <div className="sdf-section-2-text">
                    <h2>
                        How we can be<br /> your help?
                    </h2>
                    <p>Seller Drive Flair is a fully automation and AI-based technology with deep insight, that will enable businesses to take data driven decision, eventually leading them to become more profitable.</p>
                </div>
            </div>
            <div className="clr"></div>
        </section>
    )
}

function PageMainContent() {

    return (
        <main id="main" className="page-content">
            <MainBodyBanner />
            <MainBodyDashboardSection />
            <MainBodyTechnologySection />
            <div className="clr"></div>
            <MainBodyDataExploratorySection />
        </main>
    );
}

export default PageMainContent;