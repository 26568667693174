import { formatEmail, formatPhoneNumber, constants } from "../utils";

function ContactBanner() {
    const formattedNumberOne = formatPhoneNumber(constants.SDR_FLAIR_CONTACT_1)
    const formattedNumberTwo = formatPhoneNumber(constants.SDR_FLAIR_CONTACT_2)
    const formattedEmail = formatEmail(constants.SDR_FLAIR_EMAIL)
    return (
        <div className='shopify-section mount-announcement' style={{ display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            <span className='announcement'>
                <span className='announcement__text'>
                    Enquiry: <a href={formattedNumberOne}>{constants.SDR_FLAIR_CONTACT_1}</a> | <a href={formattedNumberTwo}>{constants.SDR_FLAIR_CONTACT_2}</a> | <a href={formattedEmail}>{constants.SDR_FLAIR_EMAIL}</a>
                </span>
            </span>
            <div className="login-div">
                <button className="login-button" onClick={NavigateToIdentityProvider}>
                    <span className="announcement__text">Login</span>
                </button>
            </div>
        </div>
    );
}

function NavigateToIdentityProvider() {
    const redirectUrl = `${constants.SDRF_AUTH_SERVER_API}/oauth2/authorize?response_type=code&client_id=${constants.SDRF_OAUTH_CLIENT_ID}&scope=${constants.SDRF_OAUTH_SCOPE}&redirect_uri=${constants.SDRF_OAUTH_REDIRECT_URL}`
    window.location.href = redirectUrl
}

export default ContactBanner;