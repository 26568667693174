import { Fragment } from "react";
function formatEmail(email) {
    return `mailto:${email.replaceAll(' ', '')}`
}

function formatPhoneNumber(number) {
    return `tel:${number.replaceAll(' ', '')}`
}

const marquees = [
    { altText: "Unico Organics", link: "https://cdn.shopify.com/s/files/1/0063/8792/5105/files/brand-logos-suddora-min.png" },
    { altText: 'Suddora', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/brand-logos-suddora-min.png?v=1672304206' },
    { altText: 'My Golfing Store', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/brand-logos-my-golfing-store-min.png?v=1672304206' },
    { altText: 'Trigon Sports', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/brand-logos-trigon-sports-min.png?v=1672304205' },
    { altText: 'Bio Shield Tech', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/brand-logos-bioshield-min.png?v=1679568009' },
    { altText: 'Ultimate Nutrition', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/brand-logos-ultimate-nutrition-min.png?v=1672304206' },
    { altText: 'Simple Organics', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/brand-logos-simple-organics-min.png?v=1672304206' },
    { altText: 'Just Messin', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/brand-logos-just-messin-min.png?v=1672304206' },
    { altText: 'Signatures', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/brand-logos-signatures-min.png?v=1672304206' },
    { altText: 'MIKO', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/brand-logos-MIKO-min.png?v=1672304206' },
    { altText: 'Nothing Fits But', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/brand-logos-nothing-fits-but-min.png?v=1672304206' },
    { altText: 'Santana', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/brand-logos-santana-min.png?v=1672304206' },
    { altText: 'Funny Guy Mugs', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/brand-logos-funny-guy-mugs-min.png?v=1672304206' },
    { altText: 'Shoe Land', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/brand-logos-shoe-land-min.png?v=1672304206' },
    { altText: 'Gevity Vitamins', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/brand-logos-gevity-vitamins-min.png?v=1672304206' },
    { altText: 'Nice Gummies', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/brand-logos-nice-gummies-min.png?v=1672304205' },
    { altText: 'Vast Exact', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/brand-logos-vast-exact-min.png?v=1672304206' },
    { altText: 'Med Massager', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/brand-logos-med-massager-min.png?v=1672304206' },
    { altText: 'Pure By Nature', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/brand-logos-pure-by-nature-min.png?v=1672304207' },
    { altText: 'Anthony Veer', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/brand-logos-anthony-veer-min.png?v=1672304206' },
    { altText: 'Ancient Bliss', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/brand-logos-ancient-bliss-min.png?v=1672304206' },
    { altText: 'Pretty Seamless', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/brand-logos-pretty-seamless-min.png?v=1672304206' },
    { altText: 'Under Summer', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/brand-logos-under-summer-min.png?v=1672304206' }
]

const technologyMarquees = [
    { altText: "Google Cloud", link: "https://cdn.shopify.com/s/files/1/0063/8792/5105/files/tech-logos-GCP.png?v=1683917416" },
    { altText: 'Looker Studio', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/tech-logos-Looker.png?v=1683917416' },
    { altText: 'Amazon Web Services', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/tech-logos-AWS.png?v=1683916525' },
    { altText: 'Google Cloud Data Fusion', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/tech-logos-CloudDataFusion.png?v=1683916525' },
    { altText: 'Apache Spark', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/tech-logos-ApacheSpark.png?v=1683916525' },
    { altText: 'Apache Airflow', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/tech-logos-ApacheAirflow.png?v=1683916525' },
    { altText: "Google Cloud", link: "https://cdn.shopify.com/s/files/1/0063/8792/5105/files/tech-logos-GCP.png?v=1683917416" },
    { altText: 'Looker Studio', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/tech-logos-Looker.png?v=1683917416' },
    { altText: 'Amazon Web Services', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/tech-logos-AWS.png?v=1683916525' },
    { altText: 'Google Cloud Data Fusion', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/tech-logos-CloudDataFusion.png?v=1683916525' },
    { altText: 'Apache Spark', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/tech-logos-ApacheSpark.png?v=1683916525' },
    { altText: 'Apache Airflow', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/tech-logos-ApacheAirflow.png?v=1683916525' },
    { altText: "Google Cloud", link: "https://cdn.shopify.com/s/files/1/0063/8792/5105/files/tech-logos-GCP.png?v=1683917416" },
    { altText: 'Looker Studio', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/tech-logos-Looker.png?v=1683917416' },
    { altText: 'Amazon Web Services', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/tech-logos-AWS.png?v=1683916525' },
    { altText: 'Google Cloud Data Fusion', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/tech-logos-CloudDataFusion.png?v=1683916525' },
    { altText: 'Apache Spark', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/tech-logos-ApacheSpark.png?v=1683916525' },
    { altText: 'Apache Airflow', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/tech-logos-ApacheAirflow.png?v=1683916525' },
    { altText: 'Apache Airflow', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/tech-logos-ApacheAirflow.png?v=1683916525' },
    { altText: "Google Cloud", link: "https://cdn.shopify.com/s/files/1/0063/8792/5105/files/tech-logos-GCP.png?v=1683917416" },
    { altText: 'Looker Studio', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/tech-logos-Looker.png?v=1683917416' },
    { altText: 'Amazon Web Services', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/tech-logos-AWS.png?v=1683916525' },
    { altText: 'Google Cloud Data Fusion', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/tech-logos-CloudDataFusion.png?v=1683916525' },
    { altText: 'Apache Spark', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/tech-logos-ApacheSpark.png?v=1683916525' },
    { altText: 'Apache Airflow', link: 'https://cdn.shopify.com/s/files/1/0063/8792/5105/files/tech-logos-ApacheAirflow.png?v=1683916525' },
]


const footerItems = {
    legalTerms: [
        { name: "Disclaimer", link: "/pages/disclaimer" },
        { name: "Privacy Policy", link: "/pages/privacy-policy" },
        { name: "Refund Policy", link: "/pages/refund-policy" },
        { name: "Terms of Use", link: "/pages/terms-of-use" },
        { name: "Terms of Service", link: "/policies/terms-of-service" },
        { name: "Refund policy", link: "/policies/refund-policy" },
    ],
    contactDetails: {
        skype: { name: "SellerDriveGlobal", link: "/" },
        sdrContactPage: { name: "Contact Us", link: "https://www.sellerdrive.com/pages/contact-us" },
        sdrServicePackagePage: { name: "Complete Service Packs", link: "https://www.sellerdrive.com/collections/all" }
    },
    socialMedia: [],
    partners: [],
    textual: {
        aboutUs: "Seller Drive feels proud and overwhelmed by aiding sellers in every step of the way starting with Shopify store launch, complete account management on Amazon, global selling, enhancing visibility, advertisement, content, reporting, and list goes on. Our qualified team will be delighted to back you up making your business grow with boosted brand value leading to progression in sales and growth. Seller Drive will be your “Drive to Success”",
        copyRight: (<Fragment>Copyright © 2018-2023 <a href="/">Seller Drive</a><span className="powered-by-shopify" style={{ display: 'none' }}>. <a target="_blank" rel="noreferrer" href="https://www.shopify.com?utm_campaign=poweredby&amp;utm_medium=shopify&amp;utm_source=onlinestore">Powered by Shopify</a></span></Fragment>),
        drivenBy: (<Fragment>Everything on this store has been created with lots of effort and love! <br />It's Owned, Managed &amp; Funded by Proud Indian Family!</Fragment>)
    }
}

const statistics = {
    header: "why Seller Drive",
    description: (<Fragment>Seller Drive’s definite aim covers enabling sellers to launch, manage and grow their business in this
        amazing world of eCommerce. We will assist you with everything you need to be successful on the most
        trusted and leading giants - Amazon and Shopify. One Stop eCommerce Solution.<br /><br /></Fragment>)
}


const constants = Object.freeze({
    SDR_FLAIR_LOGO_ALT_TEXT: "Seller Drive Flair",
    SDR_FLAIR_EMAIL: "insight@sellerdriveflair.com",
    SDR_FLAIR_CONTACT_1: '+1 828 782 3538',
    SDR_FLAIR_CONTACT_2: '+1 828 330 3104',
    SDR_GLOBAL_MAIN: "https://www.sellerdrive.com",
    SDR_MARQUEE_ITEMS: marquees,
    SDR_FLAIR_TECH_MARQUEE_ITEMS: technologyMarquees,
    SDR_FLAIR_FOOTER_ITEMS: footerItems,
    SDR_FLAIR_STATISTICS: statistics,
    SDRF_AUTH_SERVER_API: "https://auth.sellerdriveflair.com",
    SDRF_OAUTH_CLIENT_ID: "40h6pcu24tg9sg85cclahips9n",
    SDRF_OAUTH_SCOPE: "email profile openid",
    SDRF_OAUTH_REDIRECT_URL: "https://app.sellerdriveflair.com/oauth2/callback"
})


export {
    formatEmail,
    formatPhoneNumber,
    constants
}