import {useRef, useEffect} from "react";
import { formatEmail, formatPhoneNumber, constants } from "../utils";

function TrustpilotBox() {
    const ref = useRef(null);
    useEffect(() => {
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(ref.current, true);
        }
    }, []);

    return (
        <div ref={ref} className="trustpilot-widget" data-locale="en-US" data-template-id="5419b6a8b0d04a076446a9ad" data-businessunit-id="62c67ac301d8389cff5ab1ab" data-style-height="24px" data-style-width="100%" data-theme="light" data-min-review-count="0" data-style-alignment="center">
            <a href="https://www.trustpilot.com/review/sellerdrive.com" target="_blank" rel="noreferrer">Trustpilot</a>
        </div>
    );
}

function getMarqueeItem(marqueeItem) {
    return (
        <div className="marquee-item">
            <img 
                alt={marqueeItem.altText}
                src={marqueeItem.link} 
            ></img>
        </div> 
    );
}

function MarqueeContent() {
    const marqueeItems = constants.SDR_MARQUEE_ITEMS.map((item) => {
        return getMarqueeItem(item)
    });

    return (
        <div className="footer-partner-logos">
            <div className="footer-partner-logos-inner">    
                <div className="marquee">
                    <div className="marquee-content">{marqueeItems}</div>
                </div>
            </div>
        </div>   
    );
    
}

function FooterReviews() {
    return (
        <div className="footer-reviews">  
            <div className="footer-reviews-column">
                <div className="footer-reviews-az-usa">
                    See our <strong>450+</strong> reviews on
                    <a href="https://sellercentral.amazon.com/gspn/provider-details/Enhanced%20Brand%20Content/eea64593-624f-4bcb-895c-bea5c36b4222?ref_=sc_gspn_ebclst_ebcdt-eea64593&amp;localeSelection=en_US&amp;sellFrom=US&amp;sellIn=US" target="_blank" rel="noreferrer"> ⭐ Amazon SPN (USA)</a>
                </div>
            </div>  
            <div className="footer-reviews-column">
                <div className="footer-reviews-az-india">
                    See our <strong>1900+</strong> reviews on
                    <a href="https://sellercentral.amazon.in/gspn/provider-details/Account%20Management/eea64593-624f-4bcb-895c-bea5c36b4222?ref_=sc_gspn_blst_bdt-eea64593&amp;localeSelection=en_US&amp;sellFrom=IN&amp;sellIn=IN" target="_blank" rel="noreferrer"> ⭐ Amazon SPN (India)</a>
                </div>
            </div>  
            <div className="footer-reviews-column">
                <TrustpilotBox />
            </div>  
        </div>
    );
    
}

function getLegalTermsItems({name, link}) {
    const url = `${constants.SDR_GLOBAL_MAIN}${link}`
    return (
        <>
            <span>
                <a href={url} target="_blank" rel="noreferrer" ><span className="footer-links-body">{name}</span></a>
            </span>
        </>
    )
    
}

function FooterLegalTerms() {
    const columnTitle = "Legal Terms"
    const legalTermsItems = constants.SDR_FLAIR_FOOTER_ITEMS.legalTerms.map((item) => {
        return getLegalTermsItems(item)
    })

    return (
        <div className="footer-item two-tenths lap--full-width footer-item--menus">
            <div className="two-tenths lap--five-tenths palm--full-width">
                <h5 className="footer-item__title">
                    {columnTitle}
                </h5>
                <div className="footer-links text-size--larger">
                    {legalTermsItems}
                </div>   
            </div>
        </div>
    );
}

function FooterContactDetails() {
    const columnTitle = "Connect with us"
    const formattedNumberOne = formatPhoneNumber(constants.SDR_FLAIR_CONTACT_1)
    const formattedNumberTwo = formatPhoneNumber(constants.SDR_FLAIR_CONTACT_2)
    const formattedEmail = formatEmail(constants.SDR_FLAIR_EMAIL) 

    return (
        <div className="footer-item two-tenths lap--full-width lap--push-left-none footer-item--menus">
            <div className="two-tenths lap--five-tenths palm--full-width">
                <h5 className="footer-item__title">{columnTitle}</h5>

                <div className="footer-links text-size--larger">
                    <span>
                        <a href={formattedEmail}><span className="footer-links-body">{constants.SDR_FLAIR_EMAIL}</span></a>
                    </span>
                    <span>
                        <a href={formattedNumberOne}><span className="footer-links-body">Call (#1): {constants.SDR_FLAIR_CONTACT_1}</span></a>
                    </span>
                    <span>
                        <a href={formattedNumberTwo}><span className="footer-links-body">Call (#2): {constants.SDR_FLAIR_CONTACT_2}</span></a>
                    </span>
                    <span>
                        <a href={constants.SDR_FLAIR_FOOTER_ITEMS.contactDetails.skype.link}><span className="footer-links-body">Skype: {constants.SDR_FLAIR_FOOTER_ITEMS.contactDetails.skype.name}</span></a>
                    </span>
                    <span>
                        <a href={constants.SDR_FLAIR_FOOTER_ITEMS.contactDetails.sdrContactPage.link}><span className="footer-links-body">{constants.SDR_FLAIR_FOOTER_ITEMS.contactDetails.sdrContactPage.name}</span></a>
                    </span>
                    <span>
                        <a href={constants.SDR_FLAIR_FOOTER_ITEMS.contactDetails.sdrServicePackagePage.link}><span className="footer-links-body">{constants.SDR_FLAIR_FOOTER_ITEMS.contactDetails.sdrServicePackagePage.name}</span></a>
                    </span>
                    <span>
                        <a href="https://sellerdriveconnect.com"><span className="footer-links-body">Join our Community</span></a>
                    </span>
                </div>
            </div>
        </div> 
    );
}

function FooterSocialMedia() {
    return (
        <div className="footer-item two-tenths lap--full-width footer-item--social">
            <h5 className="footer-item__title">Follow us</h5>
            <div className="social-icons">
                <a href="https://www.facebook.com/SellerDrive" target="_blank" rel="noreferrer"><span className="visually-hidden">Facebook</span><span className="icon" aria-hidden="true"><svg aria-hidden="true" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26"><path d="M24.99,0H1.16C0.43,0,0,0.43,0,1.01v23.83C0,25.57,0.43,26,1.16,26h12.71v-9.82h-3.32v-4.04h3.32V8.96c0-3.32,2.17-5.06,5.06-5.06c1.44,0,2.6,0.14,3.03,0.14v3.47h-2.02c-1.59,0-2.02,0.72-2.02,1.88v2.74h4.04l-0.87,4.04h-3.32V26h6.93c0.58,0,1.16-0.43,1.16-1.16V1.01C26,0.43,25.57,0,24.99,0z"></path></svg></span></a>  
                <a href="https://www.instagram.com/sellerdrive" target="_blank" rel="noreferrer"><span className="visually-hidden">Instagram</span><span className="icon" aria-hidden="true"><svg aria-hidden="true" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26"><path d="M13 2.4l5.2.1c1.3.1 1.9.3 2.4.4.6.2 1 .5 1.5 1s.7.9 1 1.5c.2.5.4 1.1.4 2.4.1 1.4.1 1.8.1 5.2l-.1 5.2c-.1 1.3-.3 1.9-.4 2.4-.2.6-.5 1-1 1.5s-.9.7-1.5 1c-.5.2-1.1.4-2.4.4-1.4.1-1.8.1-5.2.1s-3.9 0-5.2-.1-1.9-.3-2.4-.4c-.6-.2-1-.5-1.5-1s-.7-.9-1-1.5c-.2-.5-.4-1.1-.4-2.4-.1-1.4-.1-1.8-.1-5.2l.1-5.2c.1-1.3.3-1.9.4-2.4.2-.6.5-1 1-1.5s.9-.7 1.5-1c.5-.2 1.1-.4 2.4-.4 1.3-.1 1.7-.1 5.2-.1m0-2.3L7.7.2C6.3.2 5.4.4 4.5.7c-.8.4-1.5.8-2.3 1.5C1.5 3 1.1 3.7.7 4.5.4 5.4.2 6.3.1 7.7V13l.1 5.3c.1 1.4.3 2.3.6 3.1.3.9.8 1.6 1.5 2.3s1.4 1.2 2.3 1.5c.8.3 1.8.5 3.1.6 1.4.1 1.8.1 5.3.1l5.3-.1c1.4-.1 2.3-.3 3.1-.6.9-.3 1.6-.8 2.3-1.5s1.2-1.4 1.5-2.3c.3-.8.5-1.8.6-3.1.1-1.4.1-1.8.1-5.3l-.1-5.3c-.1-1.4-.3-2.3-.6-3.1-.3-.9-.8-1.6-1.5-2.3S22.3 1.1 21.4.8c-.8-.3-1.8-.5-3.1-.6S16.5.1 13 .1zm0 6.3c-3.7 0-6.6 3-6.6 6.6s3 6.6 6.6 6.6 6.6-3 6.6-6.6-2.9-6.6-6.6-6.6zm0 10.9c-2.4 0-4.3-1.9-4.3-4.3s1.9-4.3 4.3-4.3 4.3 1.9 4.3 4.3-1.9 4.3-4.3 4.3z"></path><circle cx="19.9" cy="6.1" r="1.6"></circle></svg></span></a>  
                <a href="https://www.youtube.com/c/sellerdrive" target="_blank" rel="noreferrer"><span className="visually-hidden">YouTube</span><span className="icon" aria-hidden="true"><svg aria-hidden="true" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26"><path d="M25.76 8.2s-.24-1.77-1.06-2.6c-.95-1.06-2.13-1.06-2.6-1.06L13 4.3l-9.1.24c-.47 0-1.65 0-2.6 1.06C.47 6.43.24 8.2.24 8.2S0 10.33 0 12.45v2c0 2.13.24 4.25.24 4.25s.24 1.77 1.06 2.6c.95 1.06 2.25.95 2.84 1.06 2.12.25 8.86.25 8.86.25l9.1-.24c.47-.12 1.65-.12 2.6-1.06.83-.83 1.06-2.6 1.06-2.6s.24-2.13.24-4.26v-2c0-2.12-.24-4.25-.24-4.25zm-15.48 8.63V9.5l6.97 3.66-6.97 3.67z"></path></svg></span></a>
            </div>
        </div>  
    );
}

function FooterPartners() {
    return (
        <div className="footer-item two-tenths lap--full-width lap--push-left-none footer-item--newsletter">
            <h5 className="footer-item__title">Our Partners</h5>
            <div className="newsletter-form mu-partner-logos">
                <img alt="Amazon SPN" src="https://cdn.shopify.com/s/files/1/0063/8792/5105/files/footer-logos-az-spn-min.png?v=1672307909"></img>
                <img alt="Amazon Global Selling" src="https://cdn.shopify.com/s/files/1/0063/8792/5105/files/footer-logos-az-gs-min.png?v=1672307909"></img>
                <img style={{ display: 'none' }} alt="Amazon ads" src="//cdn.shopify.com/s/files/1/0063/8792/5105/files/footer-logos-az-ads-min.png?v=1672307909"></img>
                <img alt="Shopify Partners" src="https://cdn.shopify.com/s/files/1/0063/8792/5105/files/footer-logos-shopify-partner-min.png?v=1672307909"></img>
                <img alt="Shopify Plus" src="https://cdn.shopify.com/s/files/1/0063/8792/5105/files/footer-logos-shopify-plus-min.png?v=1672307909"></img>
                <img alt="Amazon ads" style={{ minHeight: '100px' }} src="https://cdn.shopify.com/s/files/1/0063/8792/5105/files/AZ-Adv-Verified-partner-badge.png?v=1675746768"></img>
            </div>
        </div> 
    );
}

function FooterAboutUsDescription() {
    return (
        <div className="footer-text-sdr">
            <p className="footer-text">{constants.SDR_FLAIR_FOOTER_ITEMS.textual.aboutUs}</p>
        </div>
    );
}

function FooterCopyrightSection() {
    return (
        <div className="footer-item footer-item--copyright">
            <span>
                {constants.SDR_FLAIR_FOOTER_ITEMS.textual.copyRight}
            </span>
            <p 
                className="text-center" 
                style={{ display: 'none', color: '#fff', fontSize: '13px' }}>
                {constants.SDR_FLAIR_FOOTER_ITEMS.textual.drivenBy}
            </p>
        </div> 
    );
}

function FooterDisplay() {
    return (
        <div id="shopify-section-footer" className="shopify-section section-w-mobile-padding ten-tenths lap--full-width push-left-one-tenth lap--push-left-none">
            <footer>
                <MarqueeContent />

                <div className="clr"></div>

                <FooterReviews />

                <div className="clr"></div> 

                <FooterLegalTerms />

                <FooterContactDetails />  

                <FooterSocialMedia />   

                <FooterPartners />

                <div className="clr"></div>   

                <FooterAboutUsDescription />

                <div className="clr"></div>   

                <FooterCopyrightSection />   

            </footer>
        </div>
    );
}

export default FooterDisplay;