import { constants } from "../utils";
const sdrFlairLogo = require('../images/sdr-flair-logo.png')

function SellerDriveFlairHeader() {
    return (
        <div id="shopify-section-header" className="shopify-section">
            <header className="header-holder">
                <div className="header wrapper">
                    <div id="logo" className="logo">
                        <a 
                            className="logo-img" 
                            title={constants.SDR_FLAIR_LOGO_ALT_TEXT} 
                            tabIndex="3" 
                            href="/"
                            style={{height: 'var(--header-logo)', display: 'flex', alignItems: 'center'}}
                        >
                            <img 
                                className="hp-logo-black" 
                                src={sdrFlairLogo} 
                                alt={constants.SDR_CONNECT_LOGO_ALT_TEXT}
                                width="400"
                                height="94"
                                style={{ maxHeight: '55px'}}
                            />
                        </a>
                    </div>
                </div>
            </header>
        </div>
    );
  }


  export default SellerDriveFlairHeader;