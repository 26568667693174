import './style/theme.css'
import './style/main.css'


import ContactBanner from "./sections/announcement";
import SellerDriveFlairHeader from "./sections/header";
import PageMainContent from './sections/main';
import FooterDisplay from "./sections/footer";



function App() {
  return (
    <div >
      <ContactBanner />
      <SellerDriveFlairHeader />
      <PageMainContent />
      <FooterDisplay />
    </div>
  );
}

export default App;
